<template>
  <div>
    <TabHeader :active="active"></TabHeader>

    <div class="tab_menu">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        menu-trigger="click"
        active-text-color="#303133"
        @select="handleSelect"
        :unique-opened="true"
      >
        <el-submenu index="1" v-if="factoryTitle">
          <template slot="title">{{ factoryTitle }}</template>
          <el-menu-item
            v-for="(item, index) in factoryList"
            :key="index"
            :index="item"
          >
            {{ item }}
          </el-menu-item>
        </el-submenu>

        <el-submenu index="2">
          <template slot="title">{{ categoryTitle }}</template>
          <el-menu-item
            v-for="(item, index) in CategoryList"
            :key="index"
            :index="item"
          >
            {{ item }}
          </el-menu-item>
        </el-submenu>

        <el-submenu index="3">
          <template slot="title">{{ nameTitle }}</template>
          <el-menu-item
            v-for="(item, index) in TitleList"
            :key="index"
            :index="item"
          >
            {{ item }}
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </div>

    <DetailsHeader
      :detailsInfo="detailsInfo"
      :contrastList="contrastList"
      :otherProductList="TitleAllList"
      @changeProduct="changeProduct"
      @changeDate="changeDate"
    ></DetailsHeader>

    <div class="data_charts">
      <div>历史走势</div>

      <div class="charts" :class="detailsInfo.Category1 == '全部'?'':'charts2'">
        <el-tabs v-model="activeName" @tab-click="handleClick" v-if="detailsInfo.Category1 == '全部'">
          <el-tab-pane
            v-for="item in tabList"
            :key="item"
            :label="item"
            :name="item"
          ></el-tab-pane>
        </el-tabs>

        <div class="d-flex">
          <div class="offer_list">
            <div class="title">近14天涨跌</div>
            <ul>
              <li class="offer_item" v-for="item in offerList" :key="item.Id">
                <div>
                  <span class="offer_item_date">{{ item.date }}</span>
                  <span :class="item.color" style="margin-left: 30px;">{{ item.data }}</span>
                </div>
                <div :class="item.color">
                  <span v-if="item.color == 'red'">↑</span>
                  <span v-else-if="item.color == 'green'">↓</span>
                  {{ (item.changeData - 0).toFixed(1) }}
                </div>
              </li>
            </ul>
          </div>

          <div>
            <div class="set_charts">
              <div class="title">近14天走势</div>
              <div>
                <DetailsCharts
                  id="day"
                  :dataList="dayList"
                  :width="780"
                ></DetailsCharts>
              </div>
            </div>

            <div class="set_charts" style="margin-top: 15px">
              <div class="title">近14周涨跌</div>
              <div>
                <DetailsCharts
                  id="week"
                  :dataList="weekList"
                  type="bar"
                  color1="#AF4E3F"
                  color2="#AF4E3F"
                  :width="780"
                ></DetailsCharts>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AllFooter ref="AllFooter"></AllFooter>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: null,
      loading: null,

      detailsInfo: {},
      contrastList: [],

      date: this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}'),
      title: this.$route.query.title,

      tabList: [],
      activeName: '流沙',

      offerList: [],
      dayList: {},
      weekList: {},

      factoryList: [],
      CategoryList: [],
      TitleAllList: [],
      TitleList: [],
      activeIndex: '1',

      factoryTitle: '',
      categoryTitle: '',
      nameTitle: '',
    }
  },

  created() {
    document.documentElement.scrollTop = 0
    this.getInfo()

    if (this.$route.query.type == 0) {
      this.active = 1
    } else if (this.$route.query.type == 1) {
      this.active = 2
    } else {
      this.active = 0
    }
  },

  methods: {
    // tab点击
    handleClick() {
      this.getChartsData(this.activeName)
    },

    async handleSelect(index, name) {
      // console.log(index, name[0])
      switch (name[0]) {
        case '1':
          this.factoryTitle = index
          this.getDataList(this.factoryTitle, this.detailsInfo.Category1)
          break
        case '2':
          this.categoryTitle = index
          this.getCategorysList(index)
          break
        case '3':
          this.nameTitle = index
          await this.getInfo(index, true)
          break
      }
    },

    // 其他产品
    changeProduct(title) {
      // console.log(title)
      if (title == this.detailsInfo.Title) return
      this.getInfo(title, true)
    },

    async changeDate(date) {
      // console.log(date)
      this.date = date
      this.getInfo(this.detailsInfo.Title)
    },

    // 获取烟厂
    async getFactory() {
      const { data } = await this.$api.offer.getFactory()
      // console.log(data)
      this.factoryList = data
    },

    // 获取详情信息
    async getInfo(title, flag = false) {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0)',
      })
      const data = await this.$api.offer.getInfo({
        title: title || this.$route.query.title,
        date: this.date,
      })
      // console.log('获取详情信息', data.data, data.list)

      this.contrastList = data.list

      let res = data.data

      this.tabList = []
      if (res.OfferValue) {
        this.tabList.push('流沙')
      }
      if (res.ZSJValue) {
        this.tabList.push('珠三角')
      }
      if (res.SZValue) {
        this.tabList.push('深圳A')
      }
      if (res.SZValue2) {
        this.tabList.push('深圳B')
      }

      this.activeName = this.tabList[0]

      const classColor = this.riseAndFall(res.OfferValue, res.RetrieveValue)
      const zsjClassColor = this.riseAndFall(res.ZSJValue, res.ZSJRetrieveValue)
      const szClassColor = this.riseAndFall(res.SZValue, res.SZRetrieveValue)
      const sz2ClassColor = this.riseAndFall(res.SZValue2, res.SZRetrieveValue2)
      res.classColor = classColor
      res.zsjClassColor = zsjClassColor
      res.szClassColor = szClassColor
      res.sz2ClassColor = sz2ClassColor

      this.detailsInfo = res

      this.factoryTitle = res.Factory
      this.categoryTitle = res.Category2
      this.nameTitle = res.Title

      this.date = this.$globalFun.parseTime(new Date(res.Date), '{y}-{m}-{d}')
      this.title = res.Title
      this.getChartsData(this.activeName)

      this.getFactory()
      this.getDataList(
        this.detailsInfo.Factory,
        this.detailsInfo.Category1,
        this.detailsInfo.Category2,
        flag
      )

      this.$nextTick(() => {
        this.loading.close()
      })
    },

    // 头部联动数据
    async getDataList(factory, category1, category2, flag) {
      const { data } = await this.$api.offer.getList({
        pageindex: 1,
        factory,
        category1,
        date: this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}'),
        type: 0,
      })
      // console.log('11111', data)

      this.CategoryList = []
      data.list.forEach((item) => {
        if (item.foods.length) {
          this.CategoryList.push(item.Name)
        }
      })

      this.getCategorysList(category2 || this.CategoryList[0], flag)

      // console.log(this.CategoryList)
      if (category2) {
        this.categoryTitle = category2
      } else {
        this.categoryTitle = this.CategoryList[0]
      }
    },

    // 获取分类下属产品
    async getCategorysList(category, flag) {
      const { data } = await this.$api.offer.getCategorysList({
        pageindex: 1,
        pagesize: 200,
        category,
      })
      // console.log('22222', data)

      const list = data.list
      this.TitleAllList = list

      this.TitleList = []
      list.forEach((item) => {
        this.TitleList.push(item.Name)
      })
      if (!flag) {
        this.nameTitle = this.TitleList[0]
      }
    },

    // 获取走势图数据
    async getChartsData(area) {
      // 报价列表
      const { data: res } = await this.$api.offer.getDatelist({
        pageindex: 1,
        title: this.title,
        date: this.date,
        isLogin: true,
      })
      // console.log('获取报价列表/14天折线图', res)

      let dateList = []
      let dataList = []
      let offerList = []

      res.forEach((item) => {
        dateList.unshift(
          this.$globalFun.parseTime(new Date(item.Date), '{m}月{d}日')
        )
        switch (area) {
          case '流沙':
            dataList.unshift(item.OfferValue)
            offerList.push({
              date: this.$globalFun.parseTime(
                new Date(item.Date),
                '{y}-{m}-{d}'
              ),
              data: item.OfferValue,
              color: this.riseAndFall(item.OfferValue, item.RetrieveValue),
              changeData: this.$globalFun.calculate(
                item.OfferValue,
                item.RetrieveValue
              ),
            })
            break
          case '珠三角':
            dataList.unshift(item.ZSJValue)
            offerList.push({
              date: this.$globalFun.parseTime(
                new Date(item.Date),
                '{y}-{m}-{d}'
              ),
              data: item.ZSJValue,
              color: this.riseAndFall(item.ZSJValue, item.ZSJRetrieveValue),
              changeData: this.$globalFun.calculate(
                item.ZSJValue,
                item.ZSJRetrieveValue
              ),
            })
            break
          case '深圳A':
            dataList.unshift(item.SZValue)
            offerList.push({
              date: this.$globalFun.parseTime(
                new Date(item.Date),
                '{y}-{m}-{d}'
              ),
              data: item.SZValue,
              color: this.riseAndFall(item.SZValue, item.SZRetrieveValue),
              changeData: this.$globalFun.calculate(
                item.SZValue,
                item.SZRetrieveValue
              ),
            })
            break
          case '深圳B':
            dataList.unshift(item.SZValue2)
            offerList.push({
              date: this.$globalFun.parseTime(
                new Date(item.Date),
                '{y}-{m}-{d}'
              ),
              data: item.SZValue2,
              color: this.riseAndFall(item.SZValue2, item.SZRetrieveValue2),
              changeData: this.$globalFun.calculate(
                item.SZValue2,
                item.SZRetrieveValue2
              ),
            })
            break
        }
      })

      this.offerList = offerList
      // console.log(res, offerList)
      this.dayList = {
        xData: dateList,
        yData: dataList,
      }

      // 14周折线图
      const { data: result } = await this.$api.offer.getChart({
        title: this.title,
      })
      // console.log('获取14周折线图', result)

      let weekDate = []
      let weekData = []

      // weekDate = result.date.reverse()

      switch (area) {
        case '流沙':
          // weekData = result.offer.reverse()
          result.offer.forEach((item, index) => {
            if (item) {
              weekData.unshift(item)
              weekDate.unshift(result.date[index])
            }
          })
          break
        case '珠三角':
          // weekData = result.zsj.reverse()
          result.zsj.forEach((item, index) => {
            if (item) {
              weekData.unshift(item)
              weekDate.unshift(result.date[index])
            }
          })
          break
        case '深圳A':
          // weekData = result.sz.reverse()
          result.sz.forEach((item, index) => {
            if (item) {
              weekData.unshift(item)
              weekDate.unshift(result.date[index])
            }
          })
          break
        case '深圳B':
          // weekData = result.sz2.reverse()
          result.sz2.forEach((item, index) => {
            if (item) {
              weekData.unshift(item)
              weekDate.unshift(result.date[index])
            }
          })
          break
      }
      // console.log(weekDate, weekData)
      this.weekList = {
        xData: weekDate,
        yData: weekData,
      }
    },

    // 判断涨跌
    riseAndFall(newV, oldV) {
      if (!newV || !oldV) {
        return ''
      }

      const num = newV - oldV
      if (num > 0) {
        return 'red'
      } else if (num === 0) {
        return ''
      } else {
        return 'green'
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.loading) {
      this.loading.close()
    }
    next()
  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__item {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #898989;
}

/deep/ .el-tabs__item.is-active {
  color: #000000;
}

/deep/ .el-tabs__active-bar {
  background-color: #d83e26;
}

/deep/ .el-tabs__nav-wrap::after {
  background-color: #eff1f3;
}

.tab_menu {
  width: 1126px;
  margin: 0 auto;

  /deep/ .el-menu--horizontal > .el-menu-item {
    border-bottom: none !important;
  }

  /deep/ .el-menu--horizontal > .el-submenu .el-submenu__title {
    color: #000000 !important;
  }

  /deep/ .el-menu--horizontal > .el-submenu .el-submenu__title {
    border-bottom: none !important;
  }
}

.data_charts {
  width: 1126px;
  margin: 20px auto 0;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 600;
  color: #000000;
  text-align: left;

  .charts2 {
    padding-top: 12px !important;
  }

  .charts {
    width: 1080px;
    // height: 840px;
    padding: 0 24px 12px 22px;
    margin-top: 15px;
    background: rgba(248, 248, 248, 1);

    .offer_list {
      width: 242px;
      height: 767px;
      padding: 0 22px 0 18px;
      background: rgba(255, 255, 255, 1);

      .title {
        padding-top: 22px;
        margin-bottom: 8px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        line-height: 16px;
        color: #474747;
      }

      .offer_item {
        padding: 15px 0;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #353535;
        border-bottom: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .offer_item_date {
          display: inline-block;
          width: 90px;
        }
      }

      .offer_item:last-child {
        border-bottom: none;
      }
    }

    .set_charts {
      width: 780px;
      height: 376px;
      margin-left: 15px;
      background: rgba(255, 255, 255, 1);

      .title {
        padding-top: 23px;
        padding-left: 24px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        line-height: 16px;
        color: #474747;
      }
    }
  }
}
</style>
